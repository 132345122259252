import {Stringifier} from '../../services/stringifier.service';
import {Type} from 'class-transformer';

@Stringifier
export class EasyCastLicense {
    licenseId: number;
    licenseType: LicenseType;
    @Type(() => Date)
    createdAt: Date;
    @Type(() => Date)
    activatedAt: Date;
    @Type(() => Date)
    expiresAt: Date;
    isActive: boolean;
    numberPlaybackDevices: number;
    /**
     * Crediti per la licenza ricaricabile
     */
    credits: number;
    /**
     * Giorni per la licenza stagionale
     */
    days: number;
    userId: number;
    code = '';

    static getStringifier(level: number) {
        if (level < 0) {
            return null;
        }
        return {
            licenseId: null,
            licenseType: null,
            createdAt: null,
            activatedAt: null,
            expiresAt: null,
            isActive: null,
            numberPlaybackDevices: null,
            credits: null,
            days: null,
            userId: null,
            code: null,
        } as EasyCastLicense;
    }
}

export enum LicenseType {
    Unknown = 'Unknown',
    PayPerUse = 'PayPerUse',
    Seasonal = 'Seasonal',
    Annual = 'Annual',
    Trial = 'Trial',
}

export enum LicenseFeature {
    Unknown = 'Unknown',
    Minimal = 'Minimal',
    Base = 'Base',
    Plus = 'Plus',
}

